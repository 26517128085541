@import "~bootstrap/scss/bootstrap";
$all-colors: map-merge-multiple(
  $blues,
  $indigos,
  $purples,
  $pinks,
  $reds,
  $oranges,
  $yellows,
  $greens,
  $teals,
  $cyans
);

$utilities: map-merge(
  $utilities,
  (
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "color"), "values"),
              ($all-colors)
            ),
        )
      ),
  )
);
@import url("https://fonts.googleapis.com/css?family=Roboto:wght@400;600;700;900&display=swap");
html,
#root,
body {
  font-family: "Roboto", sans-serif !important;
  width: 100%;
  height: 100%;
}
// * {
// 	overflow: hidden !important;
// }
